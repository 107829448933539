<template>
    <div>
        <el-tabs v-model="activeTab" @tab-click="handleTabClick">
            <el-tab-pane label="居民认证" name="resident">
                <el-form :model="residentFormData" label-width="100px" ref="residentForm">
                    <el-form-item label="认证类型" prop="verifyType" style="display: none;">
                        <el-input v-model="residentFormData.verifyType" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name" required>
                        <el-input v-model="residentFormData.name" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="phone" required>
                        <el-input v-model="residentFormData.phone" placeholder="请输入手机号"></el-input>
                    </el-form-item>

                    <el-form-item label="社区" prop="community" required>
                        <!-- <el-input v-model="residentFormData.community" placeholder="请输入社区"></el-input> -->
                        <el-select v-model="residentFormData.community" placeholder="请输入社区"
                            @change="handleCommunityChange">
                            <el-option v-for="community in Tree" :key="community.deptId" :label="community.deptName"
                                :value="community.deptName">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="小区" prop="residentialArea" required>
                        <!-- <el-input v-model="residentFormData.residentialArea" placeholder="请输入小区"></el-input> -->
                        <el-select v-model="residentFormData.residentialArea" placeholder="Select Residential Area">
                            <el-option v-for="area in residentialAreaOptions" :key="area.deptId" :label="area.deptName"
                                :value="area.deptName">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="门牌号" prop="doorNumber" required>
                        <el-input v-model="residentFormData.doorNumber" placeholder="请输入门牌号"></el-input>
                    </el-form-item>
                </el-form>
                <el-tag v-if="residentFormData.active === 1" type="success">认证成功</el-tag>
                <el-tag v-else-if="residentFormData.active === 0" type="info">待审核</el-tag>
                <el-tag v-else="partyOrganizationFormData.active === -1" type="danger">被拒绝</el-tag>
            </el-tab-pane>
            <el-tab-pane label="党员认证" name="partyMember">
                <el-form :model="partyMemberFormData" label-width="100px" ref="partyMemberForm">
                    <el-form-item label="认证类型" prop="verifyType" style="display: none;">
                        <el-input v-model="partyMemberFormData.verifyType" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name" required>
                        <el-input v-model="partyMemberFormData.name" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="phone" required>
                        <el-input v-model="partyMemberFormData.phone" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="社区" prop="community" required>
                        <!-- <el-input v-model="partyMemberFormData.community" placeholder="请输入社区"></el-input> -->
                        <el-select v-model="partyMemberFormData.community" placeholder="请输入社区"
                            @change="handleCommunityChange">
                            <el-option v-for="community in Tree" :key="community.deptId" :label="community.deptName"
                                :value="community.deptName">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="地址" prop="address" required>
                        <el-input v-model="partyMemberFormData.address" placeholder="请输入地址"></el-input>
                    </el-form-item>
                </el-form>
                <el-tag v-if="partyMemberFormData.active === 1" type="success">认证成功</el-tag>
                <el-tag v-else-if="partyMemberFormData.active === 0" type="info">待审核</el-tag>
                <el-tag v-else="partyOrganizationFormData.active === -1" type="danger">被拒绝</el-tag>
            </el-tab-pane>
            <el-tab-pane label="党组织认证" name="partyOrganization">
                <el-form :model="partyOrganizationFormData" label-width="100px" ref="partyOrganizationForm">
                    <el-form-item label="认证类型" prop="verifyType" style="display: none;">
                        <el-input v-model="partyOrganizationFormData.verifyType" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name" required>
                        <el-input v-model="partyOrganizationFormData.name" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="phone" required>
                        <el-input v-model="partyOrganizationFormData.phone" placeholder="请输入手机号"></el-input>
                    </el-form-item>

                    <el-form-item label="社区" prop="community" required>
                        <!-- <el-input v-model="partyOrganizationFormData.community" placeholder="请输入社区"></el-input> -->
                        <el-select v-model="partyOrganizationFormData.community" placeholder="请输入社区"
                            @change="handleCommunityChange">
                            <el-option v-for="community in Tree" :key="community.deptId" :label="community.deptName"
                                :value="community.deptName">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="组织名称" prop="organizationName" required>
                        <el-input v-model="partyOrganizationFormData.organizationName" placeholder="请输入组织名称"></el-input>
                    </el-form-item>
                    <el-form-item label="组织代码" prop="organizationCode" required>
                        <el-input v-model="partyOrganizationFormData.organizationCode" placeholder="请输入组织代码"></el-input>
                    </el-form-item>
                </el-form>
                <el-tag v-if="partyOrganizationFormData.active === 1" type="success">认证成功</el-tag>
                <el-tag v-else-if="partyOrganizationFormData.active === 0" type="info">待审核</el-tag>
                <el-tag v-else="partyOrganizationFormData.active === -1" type="danger">被拒绝</el-tag>
            </el-tab-pane>
        </el-tabs>
        <div class="center-button">
            <el-button type="primary" @click="submitData">提交</el-button>
        </div>
    </div>
</template>

<script>
import { verify, getVerifyInfo, getTree } from '@/api/user'

export default {
    data() {
        return {
            activeTab: 'partyMember',
            residentFormData: {
                verifyType: 'resident',
                name: '',
                phone: '',
                community: '',
                residentialArea: '',
                doorNumber: '',
                active: 0 // 默认认证状态为待审核
            },
            partyMemberFormData: {
                verifyType: 'partyMember',
                name: '',
                phone: '',
                community: '',
                address: '',
                active: 0
            },
            partyOrganizationFormData: {
                verifyType: 'partyOrganization',
                name: '',
                phone: '',
                community: '',
                organizationName: '',
                organizationCode: '',
                active: 0
            },
            Tree: [], // Options for the community dropdown
            residentialAreaOptions: []
        };
    },
    created() {
        if (this.$route.params.type) {
            this.activeTab = this.$route.params.type; // 获取路由参数值并赋给authType
        }
        this.handleTabClick();
        getTree().then(res => {
            if (res.data) {
                // console.log(res.data);
                this.Tree = res.data;
            }
        }).catch(error => {
            console.error(error);
        });
    },
    methods: {
        handleCommunityChange() {
            // console.log(this.residentFormData.community);
            for (const child of this.Tree) {
                if (child.deptName === this.residentFormData.community) {
                    this.residentialAreaOptions = child.children;
                    if(child.children[0]){
                        this.residentFormData.residentialArea = child.children[0].deptName;
                    }else{
                        this.residentFormData.residentialArea = child.children[0];
                    }
                    
                }
            }

        },
        handleTabClick(tab) {
            if (this.activeTab === 'resident') {
                getVerifyInfo('resident').then(res => {
                    if (res.data) {
                        this.residentFormData = res.data;
                    }
                }).catch(error => {
                    console.error(error);
                });
            } else if (this.activeTab === 'partyMember') {
                getVerifyInfo('partyMember').then(res => {
                    if (res.data) {
                        this.partyMemberFormData = res.data;
                    }
                }).catch(error => {
                    console.error(error);
                });
            } else if (this.activeTab === 'partyOrganization') {
                getVerifyInfo('partyOrganization').then(res => {
                    if (res.data) {
                        this.partyOrganizationFormData = res.data;
                    }
                }).catch(error => {
                    console.error(error);
                });
            }
        },
        submitData() {
            let formData = {};
            if (this.activeTab === 'resident') {
                // console.log(this.residentFormData);
                formData = this.residentFormData;
            } else if (this.activeTab === 'partyMember') {
                formData = this.partyMemberFormData;
            } else if (this.activeTab === 'partyOrganization') {
                formData = this.partyOrganizationFormData;
            }

            const formRef = this.$refs[this.activeTab + 'Form'];

            formRef.validate(valid => {
                if (valid && formData.active !== 1) {
                    verify(formData).then(res => {
                        this.$message.success('信息提交成功');
                        // 如果你有一个函数来获取更新后的信息，请在这里调用
                        // this.fetchInfo()
                    }).catch(error => {
                        console.error(error);
                        this.$message.error('信息提交失败');
                    });
                } else {
                    this.$message.error('通过认证之后无法再次提交认证信息');
                    return false;
                }
            });
        }
    }
};
</script>

<style scoped>
.el-form-item__label {
    font-weight: bold;
}

.center-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
</style>